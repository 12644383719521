.notification.not .toggle {
  height: 45px;
}

.notification.not .toggle:nth-child(4) .img img {
  height: 150%;
  position: relative;
  top: -15px;
  left: -8px;
}
