.upcoming-info.review.abs .icon {
  position: relative;
  border: none;
  width: 125px;
  left: -10px;
}

.upcoming-info.review.abs .icon:nth-child(3) {
  left: 10px;
}

.upcoming-info.review.abs .icon i {
  top: 12px;
  left: 13px;
  font-size: 15px;
}

.upcoming-info.review.abs .icon:nth-child(3) i {
  left: 16px;
}

.upcoming-info.review.abs {
  height: 470px;
}
