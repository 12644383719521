.auth-container .auto-overlay {
  position: fixed;
  top: -300px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.auth-container .auto-overlay.vis {
  visibility: visible;
  opacity: 1;
  top: 0px;
}

.auth-container .auto-popup {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 600px;
  position: relative;
  transition: all 5s ease-in-out;
}

.auth-container .auto-popup h2 {
  margin-top: 0;
  color: #333;
  margin-bottom: 20px;
  font-size: 20px;
}

.auth-container .auto-popup p {
  margin: 10px 0;
  color: #333;
  font-size: 14px;
}

.auth-container .auto-popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.auth-container .auto-popup .close:hover {
  color: #1339ff;
  transform: rotate(90deg);
}

.auth-container .auto-popup .content {
  max-height: 30%;
  overflow: auto;
}

@media (max-width: 768px) {
  .auth-container .auto-popup {
    width: 90%;
  }

  .auth-container .auto-popup p,
  .auth-container .auto-popup h2 {
    font-size: unset;
  }
}

@media (max-width: 400px) {
  .auth-container .auto-popup h2 {
    margin-right: 30px;
  }
}
